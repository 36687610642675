import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ClosuresJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-closures", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Un <b>closure</b> este o functie care <i>isi aminteste</i> mediul (contextul lexical) in care a fost creata,
                    chiar daca si dupa ce acel mediu/context nu mai e activ.
                    <br/>
                    Practic, un closure permite unei functii sa acceseze variabile din exteriorul ei (din functia parinte)
                    chiar si dupa ce aceea functie parinte a fost executata.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function hello(nume){\n' +
                            '  const mesaj = "buna, ";\n' +
                            '  return function(){\n' +
                            '       console.log(mesaj + nume)\n' +
                            '  }\n' +
                            '}\n'
                        }
                    </SyntaxHighlighter>
                    Folosire:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const salutare = hello("aka");\n' +
                            'salutare()'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Funcțiile JS</b> sunt <b>valori de primă clasă</b> (pot fi atribuite și transmise la fel ca numerele sau șirurile de caractere).
                    <br/>
                    Deoarece aceste funcții dețin și accesează variabile, aceste functii își <b>mențin domeniul inițial indiferent de locul în care funcțiile sunt executate</b>.
                    Aceasta se numește <b>closure</b> (închidere).
                    <br/>
                    Modulele sunt un model de organizare a codului caracterizat prin metode publice care au acces privilegiat (prin închidere) la variabile și funcții ascunse din
                    domeniul intern al modulului.

                    <hr/>
                    Închiderea este un comportament al funcțiilor și numai al funcțiilor. Doar funcțiile au închidere.
                    <br/>
                    Nu poate avea inchidere:
                    <ul>
                        <li>un obiect</li>
                        <li>
                            o clasa
                        </li>
                    </ul>

                    Pentru ca închiderea să fie respectată, o funcție trebuie să fie invocată și, în mod specific,
                    trebuie invocată într-o ramură diferită a lanțului de domeniu de unde a fost definită inițial.
                    O funcție care se execută în același domeniu în care a fost definită nu ar prezenta niciun comportament diferit observabil,
                    cu sau fără închiderea posibilă; prin perspectiva observațională și definiție, asta nu este închidere.

                    <hr/>
                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// outer/global scope: RED(1)\n' +
                            '\n' +
                            'function lookupStudent(studentID) {\n' +
                            '    // function scope: BLUE(2)\n' +
                            '\n' +
                            '    var students = [\n' +
                            '        { id: 14, name: "Kyle" },\n' +
                            '        { id: 73, name: "Suzy" },\n' +
                            '        { id: 112, name: "Frank" },\n' +
                            '        { id: 6, name: "Sarah" }\n' +
                            '    ];\n' +
                            '\n' +
                            '    return function greetStudent(greeting){\n' +
                            '        // function scope: GREEN(3)\n' +
                            '\n' +
                            '        var student = students.find(\n' +
                            '            student => student.id == studentID\n' +
                            '        );\n' +
                            '\n' +
                            '        return `${ greeting }, ${ student.name }!`;\n' +
                            '    };\n' +
                            '}\n' +
                            '\n' +
                            'var chosenStudents = [\n' +
                            '    lookupStudent(6),\n' +
                            '    lookupStudent(112)\n' +
                            '];\n' +
                            '\n' +
                            '// accessing the function\'s name:\n' +
                            'chosenStudents[0].name;\n' +
                            '// greetStudent\n' +
                            '\n' +
                            'chosenStudents[0]("Hello");\n' +
                            '// Hello, Sarah!\n' +
                            '\n' +
                            'chosenStudents[1]("Howdy");\n' +
                            '// Howdy, Frank!'}
                    </SyntaxHighlighter>

                    Fiecare din referințele (studentID, students) de la funcția internă (greetStudent) la variabila dintr-un domeniu exterior se numește <b>închidere</b>.
                    <br/>
                    Fiecare instanță de greetStudent(..) <b>se închide peste variabilele exterioare</b> students și studentID.
                    <br/>
                    <br/>
                    Închiderea permite functiei greetStudent(..) să acceseze în continuare acele variabile exterioare chiar și după ce sfera de aplicare exterioară este terminată
                    (când lookupStudent(..) se termină cand a fost apelata).
                    <br/>
                    Si, in loc de ca variabilele students și studentID sa fie eligibile pentru GC, acestea <b>rămân în memorie</b>.
                    Mai mult decat atat, când oricare dintre instanțe functiei greetStudent(..) este invocată,
                    acele variabile sunt încă acolo, <b>păstrându-și valorile curente</b>.

                    <hr/>
                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function adder(num1) {\n' +
                            '    return function addTo(num2){\n' +
                            '        return num1 + num2;\n' +
                            '    };\n' +
                            '}\n' +
                            '\n' +
                            'var add10To = adder(10);\n' +
                            'var add42To = adder(42);\n' +
                            '\n' +
                            'add10To(15);    // 25\n' +
                            'add42To(9);     // 51'}
                    </SyntaxHighlighter>

                    <b>Inchiderea este asociată cu o instanță a unei funcții, mai degrabă decât cu definiția sa lexicală unică!!!</b>.
                    <br/>
                    <b>Închiderea este o legătură live</b>: o variabilă ținută într-o închidere poate fi actualizată (reatribuită).
                    <br/>
                    Prin închiderea peste o variabilă dintr-o funcție,
                    putem continua să folosim acea variabilă (citire și scriere) atâta timp cât acea referință la funcție există în program și de oriunde vrem să invocăm acea
                    funcție.

                    <hr/>
                    Exemplu 3: (exemplu în care variabila închisă este actualizată)
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function makeCounter() {\n' +
                            '    var count = 0;\n' +
                            '\n' +
                            '    return function getCurrent() {\n' +
                            '        count = count + 1;\n' +
                            '        return count;\n' +
                            '    };\n' +
                            '}\n' +
                            '\n' +
                            'var hits = makeCounter();\n' +
                            '\n' +
                            '// later\n' +
                            '\n' +
                            'hits();     // 1\n' +
                            '\n' +
                            '// later\n' +
                            '\n' +
                            'hits();     // 2\n' +
                            'hits();     // 3'}
                    </SyntaxHighlighter>

                    Variabila count <b>este închisă de functie interioara</b> getCurrent()!

                    <hr/>
                    Deoarece este atât de obișnuit să confundăm închiderea ca fiind orientată spre valoare în loc de orientată către variabilă,
                    dezvoltatorii se împiedică uneori să încerce să folosească închiderea pentru a păstra o valoare dintr-un moment dat. Luați în considerare:

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var studentName = "Frank";\n' +
                            '\n' +
                            'var greeting = function hello() {\n' +
                            '    // we are closing over `studentName`,\n' +
                            '    // not "Frank"\n' +
                            '    console.log(\n' +
                            '        `Hello, ${ studentName }!`\n' +
                            '    );\n' +
                            '}\n' +
                            '\n' +
                            '// later\n' +
                            '\n' +
                            'studentName = "Suzy";\n' +
                            '\n' +
                            '// later\n' +
                            '\n' +
                            'greeting();\n' +
                            '// Hello, Suzy!'}
                    </SyntaxHighlighter>

                    greeting() este închisă asupra variabilei studentName, nu asupra valorii acesteia!

                    <br/>
                    Ilustrația clasică a acestei greșeli este definirea funcțiilor în interiorul unei bucle:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var keeps = [];\n' +
                            '\n' +
                            'for (var i = 0; i < 3; i++) {\n' +
                            '    keeps[i] = function keepI(){\n' +
                            '        // closure over `i`\n' +
                            '        return i;\n' +
                            '    };\n' +
                            '}\n' +
                            '\n' +
                            'keeps[0]();   // 3 -- WHY!?\n' +
                            'keeps[1]();   // 3\n' +
                            'keeps[2]();   // 3'}
                    </SyntaxHighlighter>

                    Rezolvare problema:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var keeps = [];\n' +
                            '\n' +
                            'for (var i = 0; i < 3; i++) {\n' +
                            '    // new `j` created each iteration, which gets\n' +
                            '    // a copy of the value of `i` at this moment\n' +
                            '    let j = i;\n' +
                            '\n' +
                            '    // the `i` here isn\'t being closed over, so\n' +
                            '    // it\'s fine to immediately use its current\n' +
                            '    // value in each loop iteration\n' +
                            '    keeps[i] = function keepEachJ(){\n' +
                            '        // close over `j`, not `i`!\n' +
                            '        return j;\n' +
                            '    };\n' +
                            '}\n' +
                            'keeps[0]();   // 0\n' +
                            'keeps[1]();   // 1\n' +
                            'keeps[2]();   // 2'}
                    </SyntaxHighlighter>
                    sau (folosire let, in loc de var):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'var keeps = [];\n' +
                            '\n' +
                            'for (let i = 0; i < 3; i++) {\n' +
                            '    // the `let i` gives us a new `i` for\n' +
                            '    // each iteration, automatically!\n' +
                            '    keeps[i] = function keepEachI(){\n' +
                            '        return i;\n' +
                            '    };\n' +
                            '}\n' +
                            'keeps[0]();   // 0\n' +
                            'keeps[1]();   // 1\n' +
                            'keeps[2]();   // 2'
                        }
                    </SyntaxHighlighter>
                    Deoarece folosim let, isunt create trei, câte unul pentru fiecare buclă, astfel încât fiecare dintre <i>cele trei închideri</i> funcționează așa cum era de așteptat.

                    <hr/>
                    O închidere în JavaScript este creată atunci când o funcție este definită în cadrul unei alte funcții.
                    Permite funcției interne să acceseze variabilele și parametrii funcției exterioare,
                    chiar și după ce funcția exterioară a terminat de executat.
                    Acest lucru se întâmplă deoarece funcția interioară menține o referință la mediul său lexical,
                    surprinzând starea funcției exterioare la momentul creării ei.
                    În termeni mai simpli, o închidere permite unei funcții să acceseze variabile din domeniul său exterior chiar și după ce acel domeniu s-a închis.

                    <hr/>
                    <b>Iteratoare și generatoare</b>
                    <br/>
                    <br/>
                    Închiderile sunt utilizate în mod obișnuit în implementarea iteratoarelor și generatoarelor, permițând crearea de obiecte iterabile cu o logică de iterație
                    personalizată.
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'function createIterator(arr) {\n' +
                            '    let index = 0; // Private variable\n' +
                            '    return {\n' +
                            '        next: function() {\n' +
                            '            return index < arr.length ?\n' +
                            '                { value: arr[index++], done: false } :\n' +
                            '                { done: true };\n' +
                            '        }\n' +
                            '    };\n' +
                            '}\n' +
                            '\n' +
                            '// Example usage:\n' +
                            'const iterator = createIterator([\'a\', \'b\', \'c\']);\n' +
                            'console.log(iterator.next()); // Output: { value: \'a\', done: false }\n' +
                            'console.log(iterator.next()); // Output: { value: \'b\', done: false }\n' +
                            'console.log(iterator.next()); // Output: { value: \'c\', done: false }\n' +
                            'console.log(iterator.next()); // Output: { done: true }'
                        }
                    </SyntaxHighlighter>

                    <hr/>
                    <b>curry</b>
                    <br/>
                    <br/>

                    Currying este o tehnică în care o funcție cu mai multe argumente este transformată într-o secvență de funcții, fiecare luând un singur argument.
                    Închiderile sunt adesea folosite pentru a implementa currying în JavaScript.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function curry(fn) {\n' +
                            '    return function curried(...args) {\n' +
                            '        if (args.length >= fn.length) {\n' +
                            '            return fn(...args);\n' +
                            '        } else {\n' +
                            '            return function(...moreArgs) {\n' +
                            '                return curried(...args, ...moreArgs);\n' +
                            '            };\n' +
                            '        }\n' +
                            '    };\n' +
                            '}\n' +
                            '\n' +
                            '// Example usage:\n' +
                            'function add(a, b, c) {\n' +
                            '    return a + b + c;\n' +
                            '}\n' +
                            '\n' +
                            'const curriedAdd = curry(add);\n' +
                            'console.log(curriedAdd(1)(2)(3)); // Output: 6'}
                    </SyntaxHighlighter>

                    <hr/>
                    Urmatorul exemplu nu este inchidere:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function say(myName) {\n' +
                            '    var greeting = "Hello";\n' +
                            '    output();\n' +
                            '\n' +
                            '    function output() {\n' +
                            '        console.log(\n' +
                            '            `${ greeting }, ${ myName }!`\n' +
                            '        );\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'say("Kyle");\n' +
                            '// Hello, Kyle!'}
                    </SyntaxHighlighter>
                    Funcția internă output()accesează variabilele greetingși myNamedin domeniul de aplicare al acesteia.
                    Dar invocarea output() are loc în același domeniu, unde desigur greeting și myNamesunt încă disponibile;
                    asta este doar domeniul de aplicare lexicala, nu închidere.

                    <br/>
                    <br/>
                    Variabilele globale nu pot fi închise, deoarece sunt întotdeauna accesibile de oriunde.

                    <br/>
                    <br/>
                    Alt exempplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function lookupStudent(studentID) {\n' +
                            '    return function nobody(){\n' +
                            '        var msg = "Nobody\'s here yet.";\n' +
                            '        console.log(msg);\n' +
                            '    };\n' +
                            '}\n' +
                            '\n' +
                            'var student = lookupStudent(112);\n' +
                            '\n' +
                            'student();'}
                    </SyntaxHighlighter>
                    Funcția interioară nobody() nu se închide peste nicio variabilă exterioară - folosește doar propria sa variabilă msg.

                    <br/>
                    <br/>
                    Inchidere neobservabila:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function greetStudent(studentName) {\n' +
                            '    return function greeting(){\n' +
                            '        console.log(\n' +
                            '            `Hello, ${ studentName }!`\n' +
                            '        );\n' +
                            '    };\n' +
                            '}\n' +
                            '\n' +
                            'greetStudent("Kyle");'}
                    </SyntaxHighlighter>
                    Funcția exterioară cu siguranță este invocată.
                    Dar funcția interioară este cea care ar fi putut avea închidere,
                    și totuși nu este niciodată invocată; funcția returnată aici este doar aruncată.
                    Deci, chiar dacă din punct de vedere tehnic motorul JS a creat închidere pentru un scurt moment,
                    nu a fost observat în niciun fel semnificativ în acest program.

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ClosuresJavaScriptContent;